// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-poschodoch-tsx": () => import("./../../../src/pages/poschodoch.tsx" /* webpackChunkName: "component---src-pages-poschodoch-tsx" */),
  "component---src-pages-sluzby-tsx": () => import("./../../../src/pages/sluzby.tsx" /* webpackChunkName: "component---src-pages-sluzby-tsx" */),
  "component---src-pages-tlaciva-tsx": () => import("./../../../src/pages/tlaciva.tsx" /* webpackChunkName: "component---src-pages-tlaciva-tsx" */)
}

